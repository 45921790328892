<template>
  <div v-if="isVisible && filteredCoachmarks.length > 0">
    <!-- Only show if there are matching coachmarks -->
    <div class="overlay">
      <div v-for="(coachmark, index) in filteredCoachmarks" :key="index">
        <div v-if="elementExists(coachmark.element)">
          <div
            class="highlight-box"
            :style="getHighlightStyle(coachmark.element)"
          ></div>
          <div
            class="tooltip font-InterSemiBold"
            :style="
              getTooltipStyle(coachmark.element, coachmark.position_mobile)
            "
          >
            <span>{{ coachmark.text }}</span>
            <div class="arrow" :class="coachmark.position_mobile"></div>
          </div>
        </div>
      </div>
      <div class="button-container">
        <button
          @click="skip"
          class="font-InterSemiBold text-sm underline text-nColorLightGray"
        >
          Skip
        </button>
        <button
          @click="gotIt"
          class="font-InterSemiBold text-base rounded-3xl text-nColorButtonText px-4 py-1 ml-4 bg-nColorLightGray"
        >
          Got it
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  props: {
    group: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      coachmarks: null,
      isVisible: true,
    };
  },
  created() {
    ApiService.get(apiResource.getCustomerCoachmarks, {}).then((data) => {
      this.coachmarks = data.data.data;
    });
  },
  computed: {
    // Filter coachmarks based on the provided group prop
    filteredCoachmarks() {
      if (this.coachmarks && this.coachmarks.elements) {
        return this.coachmarks.elements.filter(
          (coachmark) => coachmark.group === this.group
        );
      }
      return [];
    },
  },
  methods: {
    // Check if the element exists in the DOM
    elementExists(selector) {
      return document.querySelector(selector) !== null;
    },
    getHighlightStyle(selector) {
      const element = document.querySelector(selector);
      if (element) {
        const rect = element.getBoundingClientRect();
        return {
          top: `${rect.top + window.scrollY}px`,
          left: `${rect.left + window.scrollX}px`,
          width: `${rect.width}px`,
          height: `${rect.height}px`,
        };
      }
      return {};
    },
    getTooltipStyle(selector, position) {
      const element = document.querySelector(selector);
      if (element) {
        const rect = element.getBoundingClientRect();
        const offset = 10;
        let top = 0,
          left = 0,
          transform = "translate(-50%, -50%)",
          textAlign = "left";

        switch (position) {
          case "right":
            top = rect.top + window.scrollY + rect.height / 2;
            left = rect.right + window.scrollX + offset;
            transform = "translate(12%, -50%)";
            textAlign = "left";
            break;
          case "left":
            top = rect.top + window.scrollY + rect.height / 2;
            left = rect.left + window.scrollX - offset - 250;
            transform = "translate(-12%, -50%)";
            textAlign = "right";
            break;
          case "bottom":
            top = rect.bottom + window.scrollY + offset;
            left = rect.left + window.scrollX + rect.width / 2;
            transform = "translate(-50%, 50%)";
            textAlign = "center";
            break;
          case "top":
          default:
            top = rect.top + window.scrollY - offset - 50;
            left = rect.left + window.scrollX + rect.width / 2;
            transform = "translate(-50%, -45%)";
            textAlign = "center";
            break;
        }

        return {
          top: `${top}px`,
          left: `${left}px`,
          transform: transform,
          "text-align": textAlign,
        };
      }
      return {};
    },
    skip() {
      this.isVisible = false;
      this.$emit("skip");
    },
    gotIt() {
      this.isVisible = false;
      this.$emit("gotIt");
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.highlight-box {
  position: absolute;
  pointer-events: none;
}

.tooltip {
  position: absolute;
  width: 250px;
  padding: 10px;
  z-index: 1001;
  text-align: left;
  transform: translate(-50%, -50%);
  color: white;
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.arrow::after {
  content: "";
  position: absolute;
  background-color: #6ec04f;
}

.arrow.right {
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent #6ec04f;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.arrow.right::after {
  width: 30px;
  height: 2px;
  left: -35px;
  top: 50%;
  transform: translateY(-50%);
}

.arrow.left {
  border-width: 5px 10px 5px 0;
  border-color: transparent #6ec04f transparent transparent;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.arrow.left::after {
  width: 30px;
  height: 2px;
  right: -35px;
  top: 50%;
  transform: translateY(-50%);
}

.arrow.bottom {
  border-width: 10px 5px 0 5px;
  border-color: #6ec04f transparent transparent transparent;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.arrow.bottom::after {
  width: 2px;
  height: 30px;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
}

.arrow.top {
  border-width: 0 5px 10px 5px;
  border-color: transparent transparent #6ec04f transparent;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.arrow.top::after {
  width: 2px;
  height: 30px;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}

.button-container {
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 1002;
}
</style>
