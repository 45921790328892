<template>
  <div>
    <Layout @skip="skip()" @gotIt="gotIt()" :group="group" v-if="desktop" />
    <LayoutMobile @skip="skip()" :group="group" @gotIt="gotIt()" v-else />
  </div>
</template>

<script>
import Layout from "@/components/Coachmarks/Layout.vue";
import LayoutMobile from "@/components/Coachmarks/LayoutMobile.vue";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "Coachmarks",
  components: { Layout, LayoutMobile },
  props: {
    group: {
      type: String,
      required: true,
    },
    desktop: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    skip() {
      ApiService.post(apiResource.coachmarkSkip, {
        group: this.group,
      });
    },
    gotIt() {
      ApiService.post(apiResource.coachmarkGotIt, {
        group: this.group,
      });
    },
  },
};
</script>

<style scoped lang="postcss"></style>
